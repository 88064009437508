import type { i18n } from 'i18next';
import i18next from 'i18next';
import type { StringValidation, ZodErrorMap, ZodTooSmallIssue } from 'zod';
import { ZodIssueCode, ZodParsedType, defaultErrorMap } from 'zod';

const jsonStringifyReplacer = <T>(_: string, value: T): string | T => {
  if (typeof value === 'bigint') {
    return value.toString();
  }
  return value;
};

function joinValues<T extends unknown[]>(array: T, separator = ' | '): string {
  return array.map(val => (typeof val === 'string' ? `'${val}'` : val)).join(separator);
}

export type MakeZodI18nMap = (option?: ZodI18nMapOption) => ZodErrorMap;

export interface ZodI18nMapOption {
  t?: i18n['t'];
  // ns?: string | readonly string[];
  handlePath?: HandlePathOption | false;
}

export interface HandlePathOption {
  context?: string;
  // ns?: string | readonly string[];
  keyPrefix?: string;
}

type OnlyString<T> = T extends string ? T : never;

const defaultNs = 'zod';
export const makeZodI18nMap: MakeZodI18nMap = option => (issue, ctx) => {
  const { t, handlePath } = {
    t: i18next.t,
    ...option,
    handlePath:
      option?.handlePath !== false
        ? {
            context: 'with_path',
            keyPrefix: undefined,
            ...option?.handlePath,
          }
        : null,
  };

  let message = defaultErrorMap(issue, ctx).message;

  const path =
    issue.path.length > 0 && !!handlePath
      ? {
          context: handlePath.context,
          path: issue.path.join('.'),
          // TODO: the should the following line to be applied ?
          // t([handlePath.keyPrefix, issue.path.join('.')].filter(Boolean).join('.'), {
          //   ns: handlePath.ns,
          //   defaultValue: issue.path.join('.'),
          // }),
        }
      : {};

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = t('errors.invalid_type_received_undefined', 'Required', { ns: defaultNs, ...path });
      } else if (issue.received === ZodParsedType.null) {
        message = t('errors.invalid_type_received_null', 'Required', { ns: defaultNs, ...path });
      } else {
        const a = {
          [ZodParsedType.function]: t('types.function', 'function', { ns: defaultNs }),
          [ZodParsedType.number]: t('types.number', 'number', { ns: defaultNs }),
          [ZodParsedType.string]: t('types.string', 'string', { ns: defaultNs }),
          [ZodParsedType.nan]: t('types.nan', 'nan', { ns: defaultNs }),
          [ZodParsedType.integer]: t('types.integer', 'integer', { ns: defaultNs }),
          [ZodParsedType.float]: t('types.float', 'float', { ns: defaultNs }),
          [ZodParsedType.boolean]: t('types.boolean', 'boolean', { ns: defaultNs }),
          [ZodParsedType.date]: t('types.date', 'date', { ns: defaultNs }),
          [ZodParsedType.bigint]: t('types.bigint', 'bigint', { ns: defaultNs }),
          [ZodParsedType.symbol]: t('types.symbol', 'symbol', { ns: defaultNs }),
          [ZodParsedType.undefined]: t('types.undefined', 'undefined', { ns: defaultNs }),
          [ZodParsedType.null]: t('types.null', 'null', { ns: defaultNs }),
          [ZodParsedType.array]: t('types.array', 'array', { ns: defaultNs }),
          [ZodParsedType.object]: t('types.object', 'object', { ns: defaultNs }),
          [ZodParsedType.unknown]: t('types.unknown', 'unknown', { ns: defaultNs }),
          [ZodParsedType.promise]: t('types.promise', 'promise', { ns: defaultNs }),
          [ZodParsedType.void]: t('types.void', 'void', { ns: defaultNs }),
          [ZodParsedType.never]: t('types.never', 'never', { ns: defaultNs }),
          [ZodParsedType.map]: t('types.map', 'map', { ns: defaultNs }),
          [ZodParsedType.set]: t('types.set', 'set', { ns: defaultNs }),
        };
        message = t('errors.invalid_type', 'Expected {{expected}}, received {{received}}', {
          expected: a[issue.expected] ?? issue.expected,
          received: a[issue.received] ?? issue.received,
          ns: defaultNs,
          ...path,
        });
      }
      break;

    case ZodIssueCode.invalid_literal:
      message = t('errors.invalid_literal', 'Invalid literal value, expected {{expected}}', {
        expected: JSON.stringify(issue.expected, jsonStringifyReplacer),
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.unrecognized_keys:
      message = t('errors.unrecognized_keys', 'Unrecognized key(s) in object: {{- keys}}', {
        keys: joinValues(issue.keys, ', '),
        count: issue.keys.length,
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.invalid_union:
      message = t('errors.invalid_union', 'Invalid input', { ns: defaultNs, ...path });
      break;

    case ZodIssueCode.invalid_union_discriminator:
      message = t('errors.invalid_union_discriminator', 'Invalid discriminator value. Expected {{- options}}', {
        options: joinValues(issue.options),
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.invalid_enum_value:
      message = t('errors.invalid_enum_value', "Invalid enum value. Expected {{- options}}, received '{{received}}'", {
        options: joinValues(issue.options),
        received: issue.received,
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.invalid_arguments:
      message = t('errors.invalid_arguments', 'Invalid function arguments', { ns: defaultNs, ...path });
      break;

    case ZodIssueCode.invalid_return_type:
      message = t('errors.invalid_return_type', 'Invalid function return type', {
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.invalid_date:
      message = t('errors.invalid_date', 'Invalid date', { ns: defaultNs, ...path });
      break;

    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('startsWith' in issue.validation) {
          message = t('errors.invalid_string.startsWith', 'Invalid input: must start with "{{startsWith}}"', {
            startsWith: issue.validation.startsWith,
            ns: defaultNs,
            ...path,
          });
        } else if ('endsWith' in issue.validation) {
          message = t('errors.invalid_string.endsWith', 'Invalid input: must end with "{{endsWith}}"', {
            endsWith: issue.validation.endsWith,
            ns: defaultNs,
            ...path,
          });
        } else if ('include' in issue.validation) {
          message = t('errors.invalid_string.include', 'Invalid input: must include "{{include}}"', {
            include: issue.validation.include,
            ns: defaultNs,
            ...path,
          });
        }
      } else {
        const validation_string = {
          email: t('validations.email', 'email', { ns: defaultNs }),
          url: t('validations.url', 'url', { ns: defaultNs }),
          emoji: t('validations.emoji', 'cuid', { ns: defaultNs }),
          uuid: t('validations.uuid', 'uuid', { ns: defaultNs }),
          nanoid: t('validations.nanoid', 'nanoid', { ns: defaultNs }),
          regex: t('validations.regex', 'regex', { ns: defaultNs }),
          cuid: t('validations.cuid', 'cuid', { ns: defaultNs }),
          cuid2: t('validations.cuid2', 'cuid2', { ns: defaultNs }),
          ulid: t('validations.ulid', 'ulid', { ns: defaultNs }),
          datetime: t('validations.datetime', 'datetime', { ns: defaultNs }),
          date: t('validations.date', 'date', { ns: defaultNs }),
          time: t('validations.time', 'time', { ns: defaultNs }),
          duration: t('validations.duration', 'duration', { ns: defaultNs }),
          ip: t('validations.ip', 'ip', { ns: defaultNs }),
          base64: t('validations.base64', 'base64', { ns: defaultNs }),
          base64url: t('validations.base64url', 'base64url', { ns: defaultNs }),
          cidr: t('validations.cidr', 'cidr', { ns: defaultNs }),
          jwt: t('validations.jwt', 'jwt', { ns: defaultNs }),
        } satisfies Record<OnlyString<StringValidation>, string>;
        const validation = validation_string[issue.validation as OnlyString<StringValidation>];
        const invalid_string = {
          date: t('errors.invalid_string.date', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          email: t('errors.invalid_string.email', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          url: t('errors.invalid_string.url', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          emoji: t('errors.invalid_string.emoji', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          uuid: t('errors.invalid_string.uuid', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          nanoid: t('errors.invalid_string.nanoid', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          regex: t('errors.invalid_string.regex', 'Invalid', { validation, ns: defaultNs, ...path }),
          cuid: t('errors.invalid_string.cuid', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          cuid2: t('errors.invalid_string.cuid2', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          ulid: t('errors.invalid_string.ulid', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          datetime: t('errors.invalid_string.datetime', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          time: t('errors.invalid_string.time', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          duration: t('errors.invalid_string.duration', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          ip: t('errors.invalid_string.ip', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          base64: t('errors.invalid_string.base64', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          base64url: t('errors.invalid_string.base64url', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          cidr: t('errors.invalid_string.cidr', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
          jwt: t('errors.invalid_string.jwt', 'Invalid {{validation}}', {
            validation,
            ns: defaultNs,
            ...path,
          }),
        } satisfies Record<OnlyString<StringValidation>, string>;

        message = invalid_string[issue.validation as OnlyString<StringValidation>];
      }
      break;

    case ZodIssueCode.too_small: {
      const minimum = issue.type === 'date' ? new Date(issue.minimum as number) : issue.minimum;
      const too_small_message_list: Record<
        ZodTooSmallIssue['type'],
        (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']) => string
      > = {
        string: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.string.exact', 'String must contain exactly {{minimum}} character(s)', {
                minimum,
                count: minimum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_small.string.inclusive', 'String must contain at least {{minimum}} character(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_small.string.not_inclusive', 'String must contain over {{minimum}} character(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        number: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.number.exact', 'Number must be exactly {{minimum}}', {
                minimum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_small.number.inclusive', 'Number must be greater than or equal to {{minimum}}', {
                    minimum,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_small.number.not_inclusive', 'Number must be greater than {{minimum}}', {
                    minimum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        bigint: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.bigint.exact', 'Number must be exactly {{minimum}}', {
                minimum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_small.bigint.inclusive', 'Number must be greater than or equal to {{minimum}}', {
                    minimum,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_small.bigint.not_inclusive', 'Number must be greater than {{minimum}}', {
                    minimum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        date: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.date.exact', 'Date must be exactly {{- minimum, datetime}}', {
                minimum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t(
                    'errors.too_small.date.inclusive',
                    'Date must be greater than or equal to {{- minimum, datetime}}',
                    {
                      minimum,
                      ns: defaultNs,
                      ...path,
                    }
                  )
                : t('errors.too_small.date.not_inclusive', 'Date must be greater than {{- minimum, datetime}}', {
                    minimum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        array: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.array.exact', 'Array must contain exactly {{minimum}} element(s)', {
                minimum,
                count: minimum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_small.array.inclusive', 'Array must contain at least {{minimum}} element(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_small.array.not_inclusive', 'Array must contain more than {{minimum}} element(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        set: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_small.set.exact', 'Set must contain exactly {{minimum}} element(s)', {
                minimum,
                count: minimum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_small.set.inclusive', 'Array must contain at least {{minimum}} element(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_small.set.not_inclusive', 'Array must contain more than {{minimum}} element(s)', {
                    minimum,
                    count: minimum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },
      };
      message = too_small_message_list[issue.type](issue.exact, issue.inclusive);

      break;
    }

    case ZodIssueCode.too_big: {
      const maximum = issue.type === 'date' ? new Date(issue.maximum as number) : issue.maximum;
      const too_big_message_list: Record<
        ZodTooSmallIssue['type'],
        (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']) => string
      > = {
        string: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.string.exact', 'String must contain exactly {{maximum}} character(s)', {
                maximum,
                count: maximum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.string.inclusive', 'String must contain at most {{maximum}} character(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.string.not_inclusive', 'String must contain under {{maximum}} character(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        number: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.number.exact', 'Number must be exactly {{maximum}}', {
                maximum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.number.inclusive', 'Number must be less than or equal to {{maximum}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.number.not_inclusive', 'Number must be less than {{maximum}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        bigint: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.bigint.exact', 'Number must be exactly {{maximum}}', {
                maximum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.bigint.inclusive', 'Number must be less than or equal to {{maximum}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.bigint.not_inclusive', 'Number must be less than {{maximum}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        date: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.date.exact', 'Date must be exactly {{- maximum, datetime}}', {
                maximum,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.date.inclusive', 'Date must be smaller than or equal to {{- maximum, datetime}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.date.not_inclusive', 'Date must be smaller than {{- maximum, datetime}}', {
                    maximum,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        array: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.array.exact', 'Array must contain exactly {{maximum}} element(s)', {
                maximum,
                count: maximum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.array.inclusive', 'Array must contain at most {{maximum}} element(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.array.not_inclusive', 'Array must contain less than {{maximum}} element(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },

        set: (exact: ZodTooSmallIssue['exact'], inclusive: ZodTooSmallIssue['inclusive']): string => {
          switch (exact) {
            case true:
              return t('errors.too_big.set.exact', 'Array must contain exactly {{maximum}} element(s)', {
                maximum,
                count: maximum as number,
                ns: defaultNs,
                ...path,
              });

            case false:
            case undefined:
              return inclusive
                ? t('errors.too_big.set.inclusive', 'Array must contain at most {{maximum}} element(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  })
                : t('errors.too_big.set.not_inclusive', 'Array must contain less than {{maximum}} element(s)', {
                    maximum,
                    count: maximum as number,
                    ns: defaultNs,
                    ...path,
                  });
          }
        },
      };
      message = too_big_message_list[issue.type](issue.exact, issue.inclusive);
      break;
    }

    case ZodIssueCode.invalid_intersection_types:
      message = t('errors.invalid_intersection_types', 'Intersection results could not be merged', {
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.not_multiple_of:
      message = t('errors.not_multiple_of', 'Number must be a multiple of {{multipleOf}}', {
        multipleOf: issue.multipleOf,
        ns: defaultNs,
        ...path,
      });
      break;

    case ZodIssueCode.not_finite:
      message = t('errors.not_finite', 'Number must be finite', {
        ns: defaultNs,
        ...path,
      });
      break;

    // biome-ignore lint/complexity/noUselessSwitchCase: this is required with the commented snippet
    case ZodIssueCode.custom:
    // WARN: The user should handle by themselves for custom validations
    // const { key, values } = getKeyAndValues(issue.params?.i18n, 'errors.custom');
    // message = t(key, {
    //   ...values,
    //   ns: defaultNs,
    //   defaultValue: message,
    //   ...path,
    // });
    default:
      if (import.meta.env.DEV) {
        console.warn('Unhandled issue', issue);
      }
  }

  return { message };
};

export const zodI18nMap = makeZodI18nMap();
