import type { InitOptions } from 'i18next';
// @ts-expect-error - i18next-loader is a virtual module
import resources from 'virtual:i18next-loader';

export const supportedLanguages = ['en', 'fr'] as const;
export type SupportedLanguages = (typeof supportedLanguages)[number];
const baseConfig: InitOptions = {
  react: { useSuspense: false },
  fallbackLng: 'en',

  debug: false,
  defaultNS: 'login',

  lowerCaseLng: true,
  supportedLngs: supportedLanguages,
  ns: [],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default.json
  },
  resources,
} as const;
export default baseConfig;
