import i18next from 'i18next';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { HydratedRouter } from 'react-router/dom';
import { z } from 'zod';

import { zodI18nMap } from '~/features/i18n/errors';
import { initializeI18nClient } from '~/features/i18n/i18n.client';

startTransition(() => {
  initializeI18nClient().then(() => {
    z.setErrorMap(zodI18nMap);
    hydrateRoot(
      document,
      <StrictMode>
        <I18nextProvider i18n={i18next}>
          <HydratedRouter />
        </I18nextProvider>
      </StrictMode>
    );
  });
});
